@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Reg-webfont.woff2") format("woff2"),
       url("../fonts/ProximaNova-Reg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Sbold-webfont.woff2") format("woff2"),
       url("../fonts/ProximaNova-Sbold-webfont.woff") format("woff");
  font-weight: $bold;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Xbold-webfont.woff2") format("woff2"),
       url("../fonts/ProximaNova-Xbold-webfont.woff") format("woff");
  font-weight: $xbold;
  font-style: normal;
  font-display: optional;
}
