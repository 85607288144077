@import "solid-helpers/variables";
@import "fonts";

html {
  font-family: $sans;
}

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
}

// Dark Mode
@media (prefers-color-scheme: dark) {
  body {
    background: #212125;
  }

  .main {
    > p, h1, h2, h3, h4 {
      color: #E1E1E3;
    }
  }

  h2 {
    color: #E1E1E3;
  }
}
